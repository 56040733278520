import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { onSearchChange } from '../../state/reducers/common';

import { graphql } from 'gatsby';
import Product from '../../components/Product';
import PageTitle from '../../components/PageTitle/PageTitle';
import _ from 'lodash';

const SearchResultList = styled.div`
  @media screen and (min-width: 768px) {
    margin-top: 50px;
  }
`;

const Search = ({ pageContext, data, location }) => {
  const { t, i18n } = useTranslation();
  const allProducts = data.allProduct.nodes;
  const gender = pageContext.gender;
  const genderPath = gender === 'men' ? '/men' : '';
  const { isMobileView, searchValue } = useSelector((state) => state.common);
  let [result, setResult] = useState([]);
  const dispatch = useDispatch();

  function onFilterResult(text) {
    if (text !== null && text !== '') {
      const filteredResult = _.filter(allProducts, function (r) {
        // console.log(r.content.name);

        return r.content.name.toLowerCase().includes(text.toLowerCase());
      });
      setResult(filteredResult);

      console.log('filteredResult', filteredResult);

      const allProductsList = filteredResult.map((item, index) => {
        const prices = item.content.price.split('/');
        let firstPrice = prices[0];
        firstPrice = firstPrice.replace('HK$', '').replace(',', '');
        firstPrice = parseInt(firstPrice, 10);

        const volumes = item.content.volume.split('/');
        let firstVolume = volumes[0];

        return {
          brand: 'BIOTHERM HK',
          category: item.categories[0].localizations[i18n.language].name,
          id: item.slug,
          name: item.content.name,
          list: item.types[0].localizations[i18n.language].name,
          price: firstPrice,
          position: index,
          variant: firstVolume,
        };
      });

      window.dataLayer.push({
        ecommerce: {
          currencyCode: 'HKD',
          impressions: {
            products: allProductsList
          }
        },
        event: 'nievent',
        event_name: 'view_item_list',
        eventAction: 'Product Impressions',
        eventCategory: 'Ecommerce',
        eventLabel: 'Product listing page',
        page_category: 'product listing page',
        site_language: i18n.language,
        brand: 'BIO',
        country: 'hk',
      });
    }
  }

  const handleClickProduct = (product) => {
    //console.log('handleClickProduct')

    const allProductsList = result.map((item, index) => {
      const prices = item.content.price.split('/');
      let firstPrice = prices[0];
      firstPrice = parseInt(firstPrice.replace('HK$', ''));

      const volumes = item.content.volume.split('/');
      let firstVolume = volumes[0];

      return {
        brand: 'BIOTHERM HK',
        category: item.categories[0].localizations[i18n.language].name,
        id: item.slug,
        name: item.content.name,
        list: item.types[0].localizations[i18n.language].name,
        price: firstPrice,
        position: index,
        variant: firstVolume,
      };
    });

    //console.log(allProductsList[product])

    window.dataLayer.push({
      ecommerce: {
        click: {
          actionField: {
            action: 'click',
            list: allProductsList[product].list,
            products: allProductsList[product]
          }
        },
      },
      event: 'productClick',
      event_name: 'select_item',
      eventAction: 'Product Click',
      eventCategory: 'Ecommerce',
      eventLabel: `${allProductsList[product].name}::${allProductsList[product].id}`,
      page_category: 'product listing page',
      site_language: i18n.language,
      brand: 'BIO',
      country: 'hk',
    });
  };


  useEffect(() => {
    onFilterResult(searchValue);
    return () => {
      dispatch(onSearchChange(null));
    };
  }, [searchValue]);

  // CHECK URL QUERY PARAMS
  useEffect(() => {
    const urlParmas = new URLSearchParams(location.search).get('q');
    if (urlParmas !== null) {
      onFilterResult(urlParmas);
    }
  }, []);

  return (
    <Layout fullwidth={isMobileView} seoSettings={{ title: t('search') }}>
      <PageTitle show>{t('search_result', { num: result.length })}</PageTitle>
      <SearchResultList className={`row no-gutters `}>
        {result.map((node, index) => {
          return (
            <Product
              key={node.id}
              id={node.id}
              classes={{ container: 'col-md-4 col-sm-6' }}
              image={node.remoteImage.childImageSharp.fluid}
              gender={gender}
              title={node.content.name}
              description={node.content.short_description}
              path={`${genderPath}/product/${node.slug}`}
              onClickFunction={() => handleClickProduct(index)}
            />
          );
        })}
      </SearchResultList>
    </Layout>
  );
};

export default Search;

export const pageQuery = graphql`
  query($locale: String, $gender: String) {
    allProduct(
      filter: { genders: { elemMatch: { slug: { eq: $gender } } } }
      sort: { order: ASC, fields: [series___sequence, sequence] }
    ) {
      nodes {
        id
        content(locale: $locale) {
          name
          short_description
          price
          volume
        }
        categories{
          localizations {
            en {
              name
            }
            zh {
              name
            }
          }
        }
        genders{
          localizations {
            en {
              name
            }
            zh {
              name
            }
          }
        }
        types{
          localizations {
            en {
              name
            }
            zh {
              name
            }
          }
        }

        slug

        remoteImage {
          childImageSharp {
            fluid(maxWidth: 105) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
